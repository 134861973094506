// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
// Models
import { DataTableItemModel } from '../models/datatable-item.model';


const API_DATATABLE_URL = 'http://45.63.89.23:8679/admin/customers';

@Injectable()
export class DataTableService  {
	/**
	 * Service Constructor
	 *
	 * @param http: HttpClient
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Returns data from fake server
	 */
	getAllItems(): Observable<DataTableItemModel[]> {

		let udata = JSON.parse(localStorage.getItem('currentuser'));
		var token = udata['access_token'];
		const headers = { 'Authorization': `bearer ${token}` }
		//return this.http.get<DataTableItemModel[]>(API_DATATABLE_URL, { headers: headers });

		
		return this.http.get<DataTableItemModel[]>(API_DATATABLE_URL, { headers: headers });

		}
}